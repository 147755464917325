const HERO_TEXT = {
    kal: {
        name: "Kal Stonestrike",
        properties: {
            Age: 18,
            Nationality: `Scottish`,
            Birthday: `November 27`,
            Likes: `Geography, his sister, Charpup, drawing.`,
            Dislikes: `Sitting still, waiting in line, travelling by portal.`,
            'Blood Type': `B-positive`
        },
        description: `Kal never had much use for "planning".
While that's all well and good for a kid, he's on the cusp of manhood now and things are starting to get complicated. Would he attend university? learn a trade? get slicked into a portal and transported to a magical land? turns out it was the third one. Ah well, maybe an epic adventure is just what Kal needs to figure out his life.`
    },
    daniel: {
        name: "Daniel Castrillo",
        properties: {
            Age: 12,
            Nationality: `Spanish`,
            Birthday: `October 24`,
            Likes: `Fantasy novels, cooking, quietly observing others.`,
            Dislikes: `Loud noises, danger, being observed by others.`,
            'Blood Type': `O-negative`
        },
        description: `Daniel, a sweet and caring boy, has spent his entire life under the thumb of an uncaring and violent father. Does Pyli represent a new beginning for him? A chance to finally live out his dreams of heroism? Or maybe this new world and its strange perils will be too much. Going from shy child to legendary warrior is a tough transition. Is Daniel ready? Only time will tell.`
    },
    tanja: {
        name: "Tanja Silver",
        properties: {
            Age: 22,
            Nationality: `American`,
            Birthday: `June 1`,
            Likes: `Beer, wine, vodka and tequila con fridays.`,
            Dislikes: `Taking anything seriously.`,
            'Blood Type': `A-negative`
        },
        description: `Tanja loves to party. there's nothing better than a weekend of pounding music, dancing, and alcohol to distract you from a disappointing world. On the weekdays, Tanja drifts through life, deploying a sarcastic, fatalistic attitude to hide her fear that... maybe this is as good as it gets? Perhaps her time in Pyli will give her the chance to connect with herself (if she's willing to).`
    },
    angelica: {
        name: "Angelica Caraxas",
        properties: {
            Age: 35,
            Nationality: `British`,
            Birthday: `September 5`,
            Likes: `Expensive foods, the law, being in control.`,
            Dislikes: `Being kidnapped from her life to fight monsters for a bunch of weirdo magicians!`,
            'Blood Type': `A-negative`
        },
        description: `Angelica actually has a lot going on in her life...or she did before that portal opened up and brought her to Pyli, now her entire legal career is on hold while she figures out the fastest way to get home cand no, she doesn't plan on staying even a second longer than she absolutely has to). Although, maybe, just maybe, this tie in pyli is just what she needs to understand that there's more to life than work.`
    },
}

let FEATURES_CAROUSEL;
let ORIGINAL_CHICKEN_POSITION = {left: 0, top: 0};

$(document).ready(function () {
    $("#backers-carousel").owlCarousel({
        items: 8,
        loop: true,
        margin: 100,
        autoplay: true,
        slideTransition: 'linear',
        autoplaySpeed: 6000,
        smartSpeed: 6000,
        dots: false,
        responsive: {
            0: {
                items: 2,
                margin: 50
            },
            // breakpoint from 480 up
            480: {
                items: 3,
                margin: 50
            },
            // breakpoint from 768 up
            768: {
                items: 4
            },
            1024: {
                items: 8,
                margin: 100
            }
        }
    });

    $('#backers-carousel').trigger('play.owl.autoplay',[1000]);

    function setSpeed(){
        $('#backers-carousel').trigger('play.owl.autoplay',[6000]);
    }

    setTimeout(setSpeed, 1000);

    FEATURES_CAROUSEL = $("#features-carousel").owlCarousel({
        items: 2,
        loop: true,
        margin: 50,
        dots: false,
        stagePadding: 100,
        responsive: {
            0: {
                items: 1,
            },
            480: {
                items: 1,
            },
            // breakpoint from 768 up
            768: {
                items: 2
            },
            1024: {
                items: 2,
            }
        }
    });

    $('#features-left-btn').on('click', () => {
        FEATURES_CAROUSEL.trigger('prev.owl.carousel', [300]);
    });

    $('#features-right-btn').on('click', () => {
        FEATURES_CAROUSEL.trigger('next.owl.carousel', [300]);
    });


    $('.heroes-imgs').on('click', (e) => {
        const hero = $(e.target).attr('data-hero');
        showHero(hero);
    });


    function showHero(heroName) {
        const details = HERO_TEXT[heroName];

        $('.hero-img-color').hide();
        $('.hero-img-bw').show();

        $('.hero-fullbody-img').hide();

        $(`.heroes-imgs[data-hero="${heroName}"]`).find('.hero-img-bw').hide();
        $(`.heroes-imgs[data-hero="${heroName}"]`).find('.hero-img-color').show();

        $(`.hero-fullbody-img[data-hero="${heroName}"]`).fadeIn();

        //update details
        $('#hero-name').text(details.name);
        $('#hero-age').text(details.properties.Age);
        $('#hero-nationality').text(details.properties.Nationality);
        $('#hero-birthday').text(details.properties.Birthday);
        $('#hero-description').text(details.description);

        $('#hero-likes').text(details.properties.Likes);
        $('#hero-dislikes').text(details.properties.Dislikes);
    }

    showHero('kal');
});


$(window).on('load', function () {
    setTimeout(() => {
        $('#loading-spinner').remove();
        $('#content').fadeIn(1000);
    }, 1000);

    startMintCountdown();
    animateChicken();
});


function startMintCountdown() {
    displayMintTimeLeft();
    setInterval(displayMintTimeLeft, 1000);
}

function displayMintTimeLeft() {
    const countDownDate = new Date(Date.UTC(2023, 4, 30, 23, 59, 59)).getTime();

    // Get todays date and time
    let now = new Date().getTime();

    // Find the distance between now an the count down date
    let distance = countDownDate - now;

    // Time calculations for days, hours, minutes and seconds
    let days = Math.floor(distance / (1000 * 60 * 60 * 24));
    let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    let seconds = Math.floor((distance % (1000 * 60)) / 1000);


    let text = '';

    if(days) {
        text += `${days}D `
    }

    if(hours) {
        text += `${hours}H `
    }

    if(minutes) {
        text += `${minutes}M `
    }

    text += `${seconds}S`;

    if(distance < 0) {
        text = 'Mint Finished';
    }

    // let randomColor = Math.floor(Math.random()*16777215).toString(16);
    //
    // $('#mint-countdown-text').css("color", `#${randomColor}`);
    $('#mint-countdown-text').text(text);
}

function randomIntFromInterval(min, max) { // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min)
}

function makeNewRelativePosition() {
    if(!ORIGINAL_CHICKEN_POSITION.left) {
        ORIGINAL_CHICKEN_POSITION = $('#chicken-running').position();
    }

    if(!ORIGINAL_CHICKEN_POSITION.left) {
        return {
            top: 0,
            left: 0
        }
    }
    const CHICKEN_SIZE = $('#chicken-running').width();

    let nt = randomIntFromInterval(CHICKEN_SIZE, $(window).height() - CHICKEN_SIZE);
    let nl = randomIntFromInterval(CHICKEN_SIZE, $(window).width() - CHICKEN_SIZE);

    return {
        top: nt - ORIGINAL_CHICKEN_POSITION.top,
        left: nl - ORIGINAL_CHICKEN_POSITION.left,
        nl: nl
    }
}


function animateChicken(){
    const newPosition = makeNewRelativePosition();

    if(!newPosition.left) {
        setTimeout(animateChicken, 50);
        return;
    }

    if(newPosition.nl < $('#chicken-running').position().left) {
        $('#chicken-running').addClass("flipped");
    } else {
        $('#chicken-running').removeClass("flipped");
    }

    $('#chicken-running').animate({  left: newPosition.left }, 10000, function(){
        setTimeout(animateChicken, 1000);
    });
};